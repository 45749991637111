<template>
  <div
    class="
      min-h-full
      flex
      items-center
      justify-center
      px-4
      sm:px-6
      lg:px-8
      mt-6
      mb-6
    "
    :class="klass"
  >
    <div
      class="max-w-md w-full space-y-8 p-6 rounded-sm border-2 border-gray-300 shadow-md"
    >
      <div>
        <img
          class="mx-auto h-12 w-auto"
          src="../assets/images/ash-text.png"
          alt="Workflow"
        />
        <h2 class="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {{ title }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Or
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            {{ link }}
          </a>
        </p>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="js">
export default {
    name: "AuthForm",
    props: {
        title: {
            type: String,
            default: "Sign in to your account"
        },
        link: {
            type: String,
            default: "Create a new account"
        },
        klass: {
            type: String,
            default: ""
        }
    }
}
</script>